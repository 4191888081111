export const fbEvent = (name, options) => {
    if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
        fbq("track", name, options); // eslint-disable-line no-undef
    }
}

export const gaEvent = (name, options) => {
    if (process.env.NODE_ENV === `production` && typeof gtag === `function`) {
        gtag('event', name, options); // eslint-disable-line no-undef
    }
}

export const generateLead = (type) => gaEvent('generate_lead', {type});

export const outboundClick = (href, name) => gaEvent('click', {
    event_category: "outbound",
    event_label: href,
    name,
    transport_type: "beacon"
});
