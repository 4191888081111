import {grommet} from "grommet/themes";
import {deepMerge} from "grommet/utils";

export const customTheme = deepMerge(grommet, {
    global: {
        // font: {
        //   family: 'Rodchenkoctt'
        // },
        colors: {
            brand: '#443997',
            'neutral-1': '#0b1225'
        }
    },
    heading: {
        weight: 800,
        color: {
            light: '#111111'
        }
    },
    calendar: {
        day: {
            extend: ({isSelected}) => `
        border-radius: 100px;
        background-color: ${isSelected ? 'white' : undefined},
      `
        }
    },
    card: {
        hover: {
            container: {
                elevation: 'xlarge',
            },
        },
        container: {
            elevation: 'medium',
            extend: `transition: all 0.2s ease-in-out;`,
        },
    }
});
