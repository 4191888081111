import React, {useContext} from 'react';
import {Anchor, Box, Button, Header as GrommetHeader, Image, Menu, ResponsiveContext, Text} from 'grommet';
import { Menu as MenuIcon } from 'grommet-icons';
import Logo from '../images/logo.svg';
import {gaEvent} from "../analytics";

const Header = () => {
    const size = useContext(ResponsiveContext);
    return (
        <GrommetHeader>
            <Box direction='row' margin={{top: 'small'}}>
                <Anchor href='/'><Image src={Logo} width={200} alignSelf='center'/></Anchor>
            </Box>
            {size === 'small' ? <Menu
                a11yTitle="Navigation Menu"
                dropProps={{ align: { top: 'bottom', right: 'right' }, round: 'small', pad: 'medium' }}
                icon={<MenuIcon color="white" size='36px'/>}
                items={[
                    {
                        label: 'Избери игра',
                        href: '#pick-a-game',
                        onClick: () => gaEvent('click', {event_category: 'pick-a-game', event_label: 'Pick a game'})
                    },
                    {
                        label: 'ЧЗВ',
                        href: '#faq',
                        onClick: () => gaEvent('click', {event_category: 'faq', event_label: 'FAQ'})
                    },
                    {
                        label: 'Цени',
                        href: '#pricing',
                        onClick: () => gaEvent('click', {event_category: 'pricing', event_label: 'Pricing'})
                    },
                    {
                        label: 'Посети ни',
                        href: '#visit-us',
                        onClick: () => gaEvent('click', {event_category: 'visit-us', event_label: 'Visit Us'})
                    },
                    {
                        label: 'Запази час',
                        href: '#join-in',
                        primary: true,
                        margin: {top: 'small'},
                        onClick: () => gaEvent('click', {event_category: 'join-in', event_label: 'Join In'})
                    }
                ]}
            /> : <Box direction='row' gap='medium' align='center'>
                <Button onClick={() => gaEvent('click', {event_category: 'pick-a-game', event_label: 'Pick a game'})} plain label={<Text weight='bold'>Избери игра</Text>} color='white' href='#pick-a-game'/>
                <Button onClick={() => gaEvent('click', {event_category: 'faq', event_label: 'FAQ'})} plain label={<Text weight='bold'>ЧЗВ</Text>} color='white' href='#faq'/>
                <Button onClick={() => gaEvent('click', {event_category: 'pricing', event_label: 'Pricing'})} plain label={<Text weight='bold'>Цени</Text>} color='white' href='#pricing'/>
                <Button onClick={() => gaEvent('click', {event_category: 'visit-us', event_label: 'Visit Us'})} plain label={<Text weight='bold'>Посети ни</Text>} color='white' href='#visit-us'/>
                <Button onClick={() => gaEvent('click', {event_category: 'join-in', event_label: 'Join In'})} primary label='Запази час' href='#join-in'/>
            </Box>}
        </GrommetHeader>
    );
};

export default Header;