import React from 'react';
import {Anchor, Box, Grommet, Image, Text} from "grommet";
import Header from "./Header";
import {customTheme} from "../theme";
import moment from "moment";
import Logo from "../images/logo.svg";

const Layout = ({children, content}) => {
    return (
        <Grommet full theme={customTheme} style={{scrollBehavior: 'smooth'}}>
            <Box
                pad={{horizontal: 'large', top: 'small', bottom: 'large'}}
                background={{
                    size: 'cover',
                    repeat: 'no-repeat',
                    position: 'top',
                    image: "url(/bg.webp)"
            }}>
                <Header/>
                {content}
            </Box>
            <Box as="main" overflow="auto">
                {children}
            </Box>
            <Box align="center" pad={{horizontal: 'large', top: 'large'}} background='neutral-1' justify='center'>
                <Box>
                    <Image src={Logo} width={300}/>
                </Box>
                <Box margin={{top: 'large', bottom: 'small'}}>
                    <Box direction='row' gap='small' alignSelf='center'>
                        <Anchor href='/terms-and-conditions' color='white'>Общи условия</Anchor>
                        <Anchor href='/privacy-policy' color='white'>Политика за поверителност</Anchor>
                    </Box>
                    <Text>© 2021 - {moment().format('YYYY')} mainaverse.com Всички права запазени.</Text>
                </Box>
            </Box>
        </Grommet>
    );
};

export default Layout;